function setVh() {
  const set = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    document.documentElement.style.setProperty("--v100vh", `${100 * vh}px`)
  }

  if ("onorientationchange" in window) {
    set()
    window.addEventListener("orientationchange", () => {
      setTimeout(() => set(), 100)
    })
  }
}

export default setVh
