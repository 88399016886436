import React, { useState, useEffect, useContext } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Context } from "~/context/Context"
import { useInView } from "react-intersection-observer"
import Link from "~/components/Link"
import NewsletterForm from "~/components/Footer/NewsletterForm"
import * as styles from "./AboutPanel.module.scss"

function AboutPanel({ introText, services, brands, columns, footerLinks }) {
  const { data, set } = useContext(Context)
  const [isNewsletterActivated, setNewsletterActivated] = useState(false)
  const [inner2, inView] = useInView()
  const [introTextRef, introTextInView] = useInView()
  const [servicesSection, servicesSectionInView] = useInView()
  const [brandsSection, brandsSectionInView] = useInView()
  const [columnsSection, columnsSectionInView] = useInView()
  const [footerSection, footerSectionInView] = useInView()

  const panelStyles = () =>
    `${styles.aboutPanel} ${
      data.isAboutPanelVisible ? styles.aboutPanel_visible : ""
    }`

  const introTextStyles = `${styles.introText} ${
    introTextInView ? styles.introText_inView : ""
  }`

  const sectionStyles = (sectionInView) => {
    return `${styles.section} ${sectionInView ? styles.section_inView : ""}`
  }

  const brandsList = (logos) =>
    logos.map(({ gatsbyImageData }, index) => (
      <div className={styles.brandListItem} key={index}>
        <GatsbyImage
          className={styles.brandListImage}
          image={gatsbyImageData}
          alt=""
        />
      </div>
    ))

  useEffect(() => {
    // TODO: refactor
    if (!inView && document.body.classList.contains("no-scroll")) {
      set({
        isAboutPanelVisible: false,
      })
    }
  }, [inView])

  return (
    <section className={panelStyles()}>
      <div className={styles.inner}>
        <div ref={inner2} className={styles.inner2}>
          <div className={styles.inner3}>
            <h2 ref={introTextRef} className={introTextStyles}>
              {introText}
            </h2>
            {services && (
              <div
                ref={servicesSection}
                className={sectionStyles(servicesSectionInView)}
              >
                <h3 className={styles.sectionTitle}>{services.title}</h3>
                <div className={styles.textColumns}>
                  {services.services.map(({ title, description }, index) => (
                    <div className={styles.textColumn} key={index}>
                      <h4
                        className={styles.textColumnTitle}
                        onClick={(e) => {
                          let accall =
                            document.querySelectorAll(".-acc-expanded")
                          accall.forEach((item) => {
                            if (item !== e.target.parentNode) {
                              item.classList.remove("-acc-expanded")
                            }
                          })
                          e.target.parentNode.classList.toggle("-acc-expanded")
                        }}
                      >
                        {title}
                      </h4>
                      <div className={styles.textColumnTextWrapper}>
                        <div className={styles.textColumnTextWrapper2}>
                          <div
                            className={styles.textColumnText}
                            dangerouslySetInnerHTML={{ __html: description }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {brands && (
              <div
                ref={brandsSection}
                className={sectionStyles(brandsSectionInView)}
              >
                <h3 className={styles.sectionTitle}>{brands.title}</h3>
                <div className={styles.brandListWrapper}>
                  <div className={styles.brandList}>
                    {brandsList(brands.logos)}
                    {brandsList(brands.logos)}
                  </div>
                </div>
              </div>
            )}
            <div
              ref={columnsSection}
              className={sectionStyles(columnsSectionInView)}
            >
              {columns && (
                <div className={styles.columns}>
                  {columns.map(
                    ({ title, teamMembers, offices, links }, index) => (
                      <div className={styles.column} key={index}>
                        <h3 className={styles.sectionTitle}>{title}</h3>
                        <ul className={styles.contactList}>
                          {teamMembers &&
                            teamMembers.map(({ name, title, email }, index) => (
                              <li
                                className={styles.contactListItem}
                                key={index}
                              >
                                <Link
                                  className={styles.contactListItemLink}
                                  to={`mailto:${email}`}
                                >
                                  <h4 className={styles.contactListItemName}>
                                    {name}
                                  </h4>
                                  {title && (
                                    <h5 className={styles.contactListItemTitle}>
                                      {title}
                                    </h5>
                                  )}
                                </Link>
                              </li>
                            ))}
                          {offices &&
                            offices.map(({ city, email }, index) => (
                              <li
                                className={styles.contactListItem}
                                key={index}
                              >
                                <Link
                                  className={styles.contactListItemLink}
                                  to={`mailto:${email}`}
                                >
                                  <h4 className={styles.contactListItemName}>
                                    {city}
                                  </h4>
                                  <h5 className={styles.contactListItemTitle}>
                                    Contact
                                  </h5>
                                </Link>
                              </li>
                            ))}
                          {links &&
                            links.map(({ text, url, email, path }, index) => (
                              <li
                                className={styles.contactListItem}
                                key={index}
                              >
                                {path && path === "/newsletter" ? (
                                  isNewsletterActivated ? (
                                    <div className={styles.newsletterWrapper}>
                                      <NewsletterForm />
                                    </div>
                                  ) : (
                                    <span
                                      className={styles.contactListItemLink}
                                      onClick={() => {
                                        setNewsletterActivated(true)
                                        setTimeout(() => {
                                          document
                                            .querySelector(
                                              "[class^='AboutPanel-module--newsletterWrapper'] input[type='email']"
                                            )
                                            .focus()
                                        }, 100)
                                      }}
                                    >
                                      <h4
                                        className={styles.contactListItemName}
                                      >
                                        {text}
                                      </h4>
                                    </span>
                                  )
                                ) : (
                                  <Link
                                    className={styles.contactListItemLink}
                                    to={
                                      url ||
                                      (email && `mailto:${email}`) ||
                                      path
                                    }
                                  >
                                    <h4 className={styles.contactListItemName}>
                                      {text}
                                    </h4>
                                  </Link>
                                )}
                              </li>
                            ))}
                        </ul>
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
            {footerLinks && (
              <div
                ref={footerSection}
                className={sectionStyles(footerSectionInView)}
              >
                <h3 className={styles.sectionTitle}>{footerLinks.title}</h3>
                <ul className={styles.contactList}>
                  {footerLinks.links.map(({ name, title, email }, index) => (
                    <li className={styles.contactListItem} key={index}>
                      <Link
                        className={styles.contactListItemLink}
                        to={`mailto:${email}`}
                      >
                        <h4 className={styles.contactListItemName}>{name}</h4>
                        {title && (
                          <h5 className={styles.contactListItemTitle}>
                            {title}
                          </h5>
                        )}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutPanel
