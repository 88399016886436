import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ContextProvider } from "~/context/Context"
import setVh from "~/helpers/setVh"
import Header from "~/components/Header"
import AboutPanel from "~/components/AboutPanel"
import "~/styles/global.scss"

function Layout({ children }) {
  // const [isInit, setInit] = useState(false)

  useEffect(() => {
    // setInit(true)
    setVh()
  }, [])

  const queryData = useStaticQuery(graphql`
    query {
      navigation: datoCmsNavigation {
        mainNavigation {
          ... on DatoCmsLink {
            text
            url
          }
          ... on DatoCmsEmailLink {
            email
            text
          }
          ... on DatoCmsInternalLink {
            id
            text
            path
          }
        }
        secondaryNavigation {
          ... on DatoCmsLink {
            text
            url
          }
          ... on DatoCmsEmailLink {
            email
            text
          }
          ... on DatoCmsInternalLink {
            id
            text
            path
          }
        }
      }
      studio: datoCmsStudio {
        headline
        services {
          title
          services {
            title
            description
          }
        }
        brands {
          title
          logos {
            gatsbyImageData(layout: FIXED, height: 45, placeholder: NONE)
          }
        }
        team {
          title
          teamMembers {
            name
            title
            email
          }
        }
        offices {
          title
          offices {
            city
            email
          }
        }
        links {
          title
          links {
            ... on DatoCmsLink {
              text
              url
            }
            ... on DatoCmsEmailLink {
              text
              email
            }
            ... on DatoCmsInternalLink {
              text
              path
            }
          }
        }
        footerLinks {
          title
          links {
            name
            title
            email
          }
        }
      }
    }
  `)

  const {
    navigation: { mainNavigation, secondaryNavigation },
    studio,
  } = queryData

  return (
    <ContextProvider>
      <Header
        mainNavigation={mainNavigation}
        secondaryNavigation={secondaryNavigation}
      />
      {/* <main className={`main-content ${isInit ? "-init" : ""}`}> */}
      <main>{children}</main>
      <AboutPanel
        introText={studio.headline}
        services={studio.services[0]}
        brands={studio.brands[0]}
        columns={[...studio.team, ...studio.offices, ...studio.links]}
        footerLinks={studio.footerLinks[0]}
      />
    </ContextProvider>
  )
}

export default Layout
