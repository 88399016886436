import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { Context } from "~/context/Context"
import Link from "~/components/Link"
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"
import * as styles from "./Header.module.scss"

function Header({ mainNavigation, secondaryNavigation }) {
  const [isVisible, setVisibility] = useState(false)
  const [hasTransition, setHasTransition] = useState(false)
  const {
    data: {
      hasPreloaderBeenShown,
      isMenuOpen,
      isTransitionOverlayVisible,
      isAboutPanelVisible,
      isTheaterModalOpen,
    },
    set,
  } = useContext(Context)

  const headerStyles = () =>
    `${styles.header} ${isVisible ? styles.header_visible : ""} ${
      isMenuOpen ? styles.header_menuOpen : ""
    } ${hasTransition ? styles.header_hasTransition : ""}`

  const pageTransitionOverlayStyles = `${styles.pageTransitionOverlay} ${
    isTransitionOverlayVisible ? styles.pageTransitionOverlay_visible : ""
  }`

  useEffect(() => {
    if (window.location.pathname !== "/") {
      set({ hasPreloaderBeenShown: true })
    }
  }, [])

  useEffect(() => {
    if (hasPreloaderBeenShown) {
      if (window.location.pathname === "/") {
        setHasTransition(true)
      }
      setVisibility(true)
    }
  }, [hasPreloaderBeenShown])

  useEffect(() => {
    const disableScroll =
      isMenuOpen || isAboutPanelVisible || isTheaterModalOpen
    let targetElement = document.body

    if (isAboutPanelVisible) {
      targetElement = document.querySelector(
        "section[class*='aboutPanel_visible']"
      )
    }

    if (disableScroll) {
      disableBodyScroll(targetElement)
    } else {
      clearAllBodyScrollLocks()
    }

    document.body.classList.toggle("no-scroll", disableScroll)
    if (isAboutPanelVisible) {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 400)
    }
  }, [isMenuOpen, isAboutPanelVisible, isTheaterModalOpen])

  function onLogoClick(event) {
    if (window.location.pathname === "/") {
      if (isMenuOpen) {
        set({ isMenuOpen: false })
      } else {
        navigate("/")
      }
    } else {
      if (isMenuOpen) {
        setTimeout(() => {
          set({ isTransitionOverlayVisible: true })
          setTimeout(() => {
            navigate("/")
            set({ isMenuOpen: false })
          }, 350)
          setTimeout(() => set({ isTransitionOverlayVisible: false }), 1200)
        }, 350)
      } else {
        set({ isTransitionOverlayVisible: true })
        setTimeout(() => {
          navigate("/")
        }, 350)
        setTimeout(() => set({ isTransitionOverlayVisible: false }), 1200)
      }
    }
    if (isAboutPanelVisible) {
      set({ isAboutPanelVisible: false })
    }
    event.preventDefault()
  }

  return (
    <header className={headerStyles()}>
      <a className={styles.logo} href="/" onClick={onLogoClick}>
        Studio SDK
      </a>
      <button
        className={styles.menuSwitcher}
        type="button"
        onClick={() => {
          set({
            // isMenuOpen: !isMenuOpen,
            isAboutPanelVisible: !isAboutPanelVisible,
          })
        }}
      >
        <span className={styles.menuSwitcherPart}></span>
        <span className={styles.menuSwitcherPart}></span>
      </button>
      <div className={styles.menu}>
        <ul className={styles.mainNavigation}>
          {mainNavigation.map(({ text, url, path, email }, index) => (
            <li
              className={styles.mainNavigationItem}
              key={index}
              style={{
                display:
                  text == "Work" ||
                  text == "Journal" ||
                  text == "Esfuerzo Mezcal®"
                    ? "none"
                    : "",
              }}
            >
              <Link
                className={styles.mainNavigationLink}
                to={
                  url ||
                  (path === "/studio" && "/") ||
                  path ||
                  (email && `mailto:${email}`)
                }
                onClick={(event) => {
                  if (path && path === "/studio") {
                    set({ isAboutPanelVisible: true })
                    setTimeout(() => {
                      set({ isMenuOpen: false })
                    }, 400)
                    event.preventDefault()
                  } else if (
                    path &&
                    (path === "/work" || path === "/journal")
                  ) {
                    if (url === window.location.pathname) {
                      set({ isMenuOpen: false })
                    } else {
                      navigate(path)
                    }
                  }
                }}
              >
                {text}
              </Link>
            </li>
          ))}
        </ul>
        <ul className={styles.secondaryNavigation}>
          {secondaryNavigation.map(({ text, url, path, email }, index) => (
            <li className={styles.secondaryNavigationItem} key={index}>
              <Link
                className={styles.secondaryNavigationLink}
                to={url || path || (email && `mailto:${email}`)}
              >
                {text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className={pageTransitionOverlayStyles}></div>
    </header>
  )
}

export default Header
