// extracted by mini-css-extract-plugin
export var bar = "Footer-module--bar--uWao7";
export var bar_inView = "Footer-module--bar_inView--AUPSL";
export var blink = "Footer-module--blink--+Hr9H";
export var colon = "Footer-module--colon--81X5x";
export var columnCell = "Footer-module--columnCell--VGdw8";
export var columnLink = "Footer-module--columnLink--nDOQA";
export var columns = "Footer-module--columns--9JPyC";
export var columns_inView = "Footer-module--columns_inView--NKEtq";
export var footer = "Footer-module--footer--LZE0a";
export var hiddenGteDesktop = "Footer-module--hidden-gte-desktop--zmtXe";
export var hiddenLtDesktop = "Footer-module--hidden-lt-desktop--LwQ-r";
export var initialPlaceholder = "Footer-module--initialPlaceholder--WeXH5";
export var navigation = "Footer-module--navigation--bWTHr";
export var newsletter = "Footer-module--newsletter--uQnnP";
export var newsletterInput = "Footer-module--newsletterInput--18c6K";
export var newsletterSubmit = "Footer-module--newsletterSubmit--SgYLG";
export var newsletterSubmit_hidden = "Footer-module--newsletterSubmit_hidden--CexII";
export var role = "Footer-module--role--GD0Zg";
export var sendingMessage = "Footer-module--sendingMessage--t8QWK";
export var successMessage = "Footer-module--successMessage--M91Bl";
export var title = "Footer-module--title--bTyDR";
export var title_inView = "Footer-module--title_inView--MNGD-";