// extracted by mini-css-extract-plugin
export var aboutPanel = "AboutPanel-module--aboutPanel--mrTBw";
export var aboutPanel_visible = "AboutPanel-module--aboutPanel_visible--1RkSS";
export var brandList = "AboutPanel-module--brandList--EHK7n";
export var brandListImage = "AboutPanel-module--brandListImage--iMELW";
export var brandListItem = "AboutPanel-module--brandListItem--ZwnxM";
export var brandListWrapper = "AboutPanel-module--brandListWrapper--9-ld7";
export var column = "AboutPanel-module--column--CuhyK";
export var columns = "AboutPanel-module--columns--gTxqK";
export var contactList = "AboutPanel-module--contactList--EPasB";
export var contactListItem = "AboutPanel-module--contactListItem--FKT6p";
export var contactListItemLink = "AboutPanel-module--contactListItemLink--zCDjK";
export var contactListItemTitle = "AboutPanel-module--contactListItemTitle--KxyS2";
export var hiddenGteDesktop = "AboutPanel-module--hidden-gte-desktop--SI6Ng";
export var hiddenLtDesktop = "AboutPanel-module--hidden-lt-desktop--eHgmk";
export var inner = "AboutPanel-module--inner--LuFKN";
export var inner2 = "AboutPanel-module--inner2--rwVHa";
export var inner3 = "AboutPanel-module--inner-3--+RP6U";
export var introText = "AboutPanel-module--introText--PHpnT";
export var introText_inView = "AboutPanel-module--introText_inView--D+Pb7";
export var newsletterWrapper = "AboutPanel-module--newsletterWrapper--g1pQf";
export var scroll = "AboutPanel-module--scroll--1D5P9";
export var section = "AboutPanel-module--section--VF3uO";
export var sectionTitle = "AboutPanel-module--sectionTitle--Gzg7x";
export var section_inView = "AboutPanel-module--section_inView--m3vhw";
export var textColumnText = "AboutPanel-module--textColumnText--MR-S6";
export var textColumnTextWrapper = "AboutPanel-module--textColumnTextWrapper--9dU-P";
export var textColumnTextWrapper2 = "AboutPanel-module--textColumnTextWrapper2--s5C7Q";
export var textColumnTitle = "AboutPanel-module--textColumnTitle--NRWD1";
export var textColumns = "AboutPanel-module--textColumns--YV1KJ";