import React from "react"

const defaultContextValue = {
  data: {
    hasPreloaderBeenShown: false,
    isMenuOpen: false,
    isTransitionOverlayVisible: false,
    isAboutPanelVisible: false,
    isTheaterModalOpen: false,
  },
  set: () => {},
}

const Context = React.createContext(defaultContextValue)
const { Provider, Consumer } = Context

class ContextProvider extends React.Component {
  constructor() {
    super()

    this.setData = this.setData.bind(this)
    this.state = {
      ...defaultContextValue,
      set: this.setData,
    }
  }

  setData(newData) {
    this.setState((state) => ({
      data: {
        ...state.data,
        ...newData,
      },
    }))
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>
  }
}

export { Consumer as default, ContextProvider, Context }
