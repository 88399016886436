import React, { useState, useRef } from "react"
import AutosizeInput from "react-input-autosize"
import addToMailchimp from "gatsby-plugin-mailchimp"
import * as styles from "./Footer.module.scss"

function NewsletterForm() {
  const [email, setEmail] = useState("")
  const [isInputFocused, setInputFocused] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [hasSent, setHasSent] = useState(false)
  const isInitialPlaceholderVisible = !isInputFocused && !email
  const newsletter = useRef(null)

  const newsletterSubmitStyles = `${styles.newsletterSubmit} ${
    isInitialPlaceholderVisible ? styles.newsletterSubmit_hidden : ""
  }`

  function focusInput() {
    newsletter.current.querySelector("input").focus()
  }

  function handleSubmit(event) {
    event.preventDefault()
    setIsSending(true)
    addToMailchimp(email)
      .then((data) => {
        setHasSent(true)
        console.log(data)
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
      })
  }

  return hasSent ? (
    <p className={styles.successMessage}>Thanks!</p>
  ) : isSending ? (
    <p className={styles.sendingMessage}>Subscribing…</p>
  ) : (
    <form
      ref={newsletter}
      className={styles.newsletter}
      onSubmit={handleSubmit}
    >
      <AutosizeInput
        className={styles.newsletterInput}
        type="email"
        value={email}
        placeholder="Your email"
        required={true}
        onChange={(event) => setEmail(event.target.value)}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
      />
      <button className={newsletterSubmitStyles} type="submit">
        Subscribe
      </button>
      {isInitialPlaceholderVisible && (
        <span
          className={styles.initialPlaceholder}
          onClick={() => focusInput()}
        >
          Sign up for our Newsletter
        </span>
      )}
    </form>
  )
}

export default NewsletterForm
