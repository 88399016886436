// extracted by mini-css-extract-plugin
export var header = "Header-module--header--NXzL8";
export var header_hasTransition = "Header-module--header_hasTransition--X3aX0";
export var header_menuOpen = "Header-module--header_menuOpen--E3jF-";
export var header_visible = "Header-module--header_visible--D19C5";
export var hiddenGteDesktop = "Header-module--hidden-gte-desktop--eR5Pz";
export var hiddenLtDesktop = "Header-module--hidden-lt-desktop--0HM-E";
export var logo = "Header-module--logo--7bOtT";
export var mainNavigation = "Header-module--mainNavigation--KCBrJ";
export var mainNavigationItem = "Header-module--mainNavigationItem--ZizS9";
export var mainNavigationLink = "Header-module--mainNavigationLink--qZfOK";
export var menu = "Header-module--menu--jC38V";
export var menuSwitcher = "Header-module--menuSwitcher--Z78wp";
export var menuSwitcherPart = "Header-module--menuSwitcherPart--Jv8kw";
export var pageTransitionOverlay = "Header-module--pageTransitionOverlay--w-Uez";
export var pageTransitionOverlay_visible = "Header-module--pageTransitionOverlay_visible--gY01T";
export var secondaryNavigation = "Header-module--secondaryNavigation--fWMn1";
export var secondaryNavigationItem = "Header-module--secondaryNavigationItem--5CqvV";
export var secondaryNavigationLink = "Header-module--secondaryNavigationLink--8Xo5S";