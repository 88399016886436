import React from "react"
import { Link as GatsbyLink } from "gatsby"

function Link({ to, className, children, onClick }) {
  const isMailto = to.substr(0, 7) === "mailto:"
  const isExternal = to.substr(0, 4) === "http"

  return isMailto || isExternal ? (
    <a
      className={className}
      href={to}
      target={isExternal ? "_blank" : null}
      rel={isExternal ? "noreferrer" : null}
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <GatsbyLink className={className} to={to} onClick={onClick}>
      {children}
    </GatsbyLink>
  )
}

export default Link
